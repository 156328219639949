import { Fade, Zoom } from "react-reveal";
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";

function Hero() {
  return (
    <>
    <Nav />
    <section id="Hero" className="bg-[#010001] border-b-[4px] border-[#EF901C] overflow-hidden">
      <div className="max-w-screen-xl mx-auto pt-32 relative">
        <div className="absolute lg:top-16 top-32 left-0 right-0 bottom-0 flex items-center justify-center">
          <div>
            <Fade left>
              <h2 className="text-[#EF901C] lg:text-[5rem] sm:text-5xl text-3xl leading-[1] font-bold text-start">STRIDE</h2>
            </Fade>
            <Fade left>
              <h1 className="text-white lg:text-[5rem] sm:text-5xl text-3xl leading-[1] font-bold">Malaysia Future Soldier</h1>
            </Fade>
            <Fade right>
              <h3 className="text-end text-[#EF901C] font-bold lg:text-3xl sm:text-3xl text-2xl">Virtual Reality</h3>
            </Fade>
          </div>
        </div>
        {/* <img src="/hero-01.png" alt="Hero section image - tentara stride" width="100%" /> */}
        <video width="1280" height="720" autoPlay loop muted >
          <source src={`/video-weapon-02.mkv`} type="video/mp4"/>
          Your browser does not support the video tag.
        </video>
      </div>
    </section>
    <Footer/>
    </>
  );
}

export default Hero;
import { useRouteError } from "react-router-dom";

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <div id="error-page" className="max-w-screen-md mx-auto text-center flex justify-center items-center align-middle min-h-screen flex-col">
      <h1 className="font-bold text-3xl">Oops!</h1>
      <p className="text-xl py-8">Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
    </div>
  );
}
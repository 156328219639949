import { Fade } from "react-reveal";
import { useState} from "react";
import Rodal from "rodal";
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";


function MetaQuest() {
  const [modal, setModal] = useState(false);

  return (
    <>
    <Nav/>
    <section id="accessories" className="bg-[#232323] border-b-[4px] border-[#EF901C] mt-24">
      <div className="max-w-screen-lg mx-auto py-16 lg:px-16 sm:px-16 px-6 ">
        <div className="grid grid-cols-12 lg:gap-x-4 lg:gap-y-4 sm:gap-4 gap-3">
          <Fade left cascade>
          <div className="col-span-4 lg:h-72 overflow-hidden lg:rounded-3xl rounded-xl">
            <img onClick={() => setModal(true)} src={`/quest-baru-01.png`} alt="Meta quest 1" className="h-full"/>
          </div>
          </Fade>
          <Fade right cascade>
          <div className="col-span-8 lg:h-72 overflow-hidden lg:rounded-3xl rounded-xl">
            <img src={`/quest-baru-02.png`} alt="Meta quest 2" className="h-full"/>
          </div>
          </Fade>
          <Fade left cascade>
          <div className="col-span-5 row-span-2 lg:pr-0 lg:pt-0">
            <img src={`/quest-baru-03.png`} alt="Meta Quest 3" className="h-full"/>
          </div>
          </Fade>
          <Fade right cascade>
          <div className="col-span-7 lg:pt-0  ">
            <h2 className="text-white lg:text-6xl sm:text-5xl text-2xl font-bold">META QUEST 2</h2>
            <h2 className=" text-[#EF901C] lg:text-6xl sm:text-5xl text-2xl lg:mt-0 sm:-mt-1 -mt-2 font-bold">GUNSTOCK</h2>
          </div>
          </Fade>
          <Fade right cascade>
          <div className="col-span-7">
            <img src={`/quest-baru-04.png`} alt="Meta Quest 4" className="h-full"/>
          </div>
          </Fade>
        </div>
        {/* <img src="/meta-quest.png" alt="Meta Quest Image" width="80%" className="mx-auto" /> */}
      </div>

      <Rodal width={400} height={570} className="rounded-xl" visible={modal} animation={'slideUp'} duration={400} onClose={() => setModal(false)}>
        <div className="h-fit lg:p-6 px-4 py-4 rounded-xl">
          <img src="/quest-baru-01.png" alt="sebuah gambar quest baru" className="h-full w-full" />
          <h2 className="text-black text-3xl font-bold text-center">VR Environtment</h2>
          <p className="text-black text-sm mt-3">
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
            magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
            consequat."
          </p>
        </div>
      </Rodal>
    </section>
    <Footer/>
    </>
  )
}

export default MetaQuest;
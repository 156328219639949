import { Fade } from "react-reveal";
import { useState, useEffect} from "react";
import Rodal from "rodal";
import { Tooltip } from "flowbite-react";
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";
import About from "./About";

function GearArmy() {
  const [headGear, setHeadGear] = useState(false);
  const [clothing, setClothing] = useState(false);
  const [armor, setArmor] = useState(false);
  const [exoSkeleton, setExoSkeleton] = useState(false);
  const [communicationSystem, setCommunicationSystem] = useState(false);


  useEffect(() => { 

  }, []);

  return (
    <>
    <Nav/>
    <section id="feature" className="bg-[#010001] border-b-[4px] border-[#EF901C] overflow-hidden mt-16">
      <div className="max-w-screen-xl mx-auto lg:pb-0 sm:pb-2 relative">
        <div className="relative sm:h-auto h-[800px]">
          {/* <img src={`/army-gear.png`} alt="Background About Future Soldier"
            className="col-span-12 object-cover object-top lg:h-[818px] h-[712px] text-center ml-32 lg:mx-auto lg:pt-28 sm:pt-0 pt-28 "/> */}
          <video width="1280" height="720" autoPlay loop muted >
            <source src={`/video-a-pose.mkv`} type="video/mp4"/>
            Your browser does not support the video tag.
          </video>

          <svg width={14} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="blinking absolute z-[8] top-[228px] left-[48.2%]">
            <path fill="white" d="M448 256A192 192 0 1 0 64 256a192 192 0 1 0 384 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm256 80a80 80 0 1 0 0-160 80 80 0 1 0 0 160zm0-224a144 144 0 1 1 0 288 144 144 0 1 1 0-288zM224 256a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"/>
          </svg>
          <div className="absolute z-10 top-[190px] left-[47.3%]" >
            <Tooltip content="Head Gear" className="whitespace-nowrap" style={`light`}>
            <button onClick={() => {setHeadGear(true); const myVid =document.getElementById("vidGear"); myVid.currentTime = 0; myVid.play(); }} className="rounded-full bg-blue-600 opacity-0 border relative border-white p-8">
              
            </button>
            </Tooltip>
          </div>
          <Rodal width={700} height={570} className="rounded-xl" visible={headGear} animation={'slideUp'} duration={400} onClose={() => setHeadGear(false)}>
            <div className="h-fit lg:p-0 px-4 py-4 rounded-xl">
              {/* <img src="/quest-baru-01.png" alt="sebuah gambar quest baru" className="h-full w-full" /> */}
              <video id="vidGear" width="1280" height="720" autoPlay muted >
                <source src={`/video-isi-head2.mkv`} type="video/mp4"/>
                Your browser does not support the video tag.
              </video>
              <h2 className="text-black text-3xl font-bold text-center mt-4">Head Gear</h2>
              <p className="text-black text-sm mt-3">
                <ul className="opacity-100 text-center">
                  <li className="lg:text-xl sm:text-sm text-base">• Extreme Head Protection</li>
                  <li className="lg:text-xl sm:text-sm text-base">• Enhance Awareness</li>
                  <li className="lg:text-xl sm:text-sm text-base">• Provide Critical Data</li>
                </ul>
              </p>
            </div>
          </Rodal>

          <svg width={14} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="blinking absolute z-[8] top-[200px] left-[52%]">
            <path fill="white" d="M448 256A192 192 0 1 0 64 256a192 192 0 1 0 384 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm256 80a80 80 0 1 0 0-160 80 80 0 1 0 0 160zm0-224a144 144 0 1 1 0 288 144 144 0 1 1 0-288zM224 256a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"/>
          </svg>
          <div className="absolute z-[9] top-[182px] left-[46.7%]">
            <Tooltip content="Communication System" className="whitespace-nowrap" style={`light`} placement="right">
            <button onClick={() => {setCommunicationSystem(true); const myVid =document.getElementById("vidComm"); myVid.currentTime = 0; myVid.play(); }} className=" rounded-full bg-red-600 opacity-0 border border-white p-10"></button>
            </Tooltip>
          </div>
          <Rodal width={700} height={570} className="rounded-xl" visible={communicationSystem} animation={'slideUp'} duration={400} onClose={() => setCommunicationSystem(false)}>
            <div className="h-fit lg:p-0 px-4 py-4 rounded-xl">
              {/* <img src="/quest-baru-01.png" alt="sebuah gambar quest baru" className="h-full w-full" /> */}
              <video id="vidComm" width="1280" height="720" autoPlay muted >
                <source src={`/video-isi-comm.mkv`} type="video/mp4"/>
                Your browser does not support the video tag.
              </video>
              <h2 className="text-black text-3xl font-bold text-center mt-4">Communication System</h2>
              <p className="text-black text-sm mt-3">
                <ul className="opacity-100 text-center">
                  <li className="lg:text-xl sm:text-sm text-base">• Data Fusion and Real Time</li>
                  <li className="lg:text-xl sm:text-sm text-base">• Tactical Communication</li>
                </ul>
              </p>
            </div>
          </Rodal>
          
          <svg width={14} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="blinking absolute z-[8] top-[302px] left-[49.5%]">
            <path fill="white" d="M448 256A192 192 0 1 0 64 256a192 192 0 1 0 384 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm256 80a80 80 0 1 0 0-160 80 80 0 1 0 0 160zm0-224a144 144 0 1 1 0 288 144 144 0 1 1 0-288zM224 256a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"/>
          </svg>
          <div className="absolute z-[9] top-[262px] left-[46.7%]" >
            <Tooltip content="Armor & Sensor" className="whitespace-nowrap" style={`light`}>
              <button onClick={() => {setArmor(true); const myVid =document.getElementById("vidArmor"); myVid.currentTime = 0; myVid.play(); }} className="rounded-xl bg-orange-600 opacity-0 border border-white px-11 py-8"></button>
            </Tooltip>
          </div>
          <Rodal width={700} height={570} className="rounded-xl" visible={armor} animation={'slideUp'} duration={400} onClose={() => setArmor(false)}>
            <div className="h-fit lg:p-0 px-4 py-4 rounded-xl">
              {/* <img src="/quest-baru-01.png" alt="sebuah gambar quest baru" className="h-full w-full" /> */}
              <video id="vidArmor" width="1280" height="720" autoPlay muted >
                <source src={`/video-isi-vest.mkv`} type="video/mp4"/>
                Your browser does not support the video tag.
              </video>
              <h2 className="text-black text-3xl font-bold text-center mt-4">Armor & Sensor</h2>
              <p className="text-black text-sm mt-3">
                <ul className="opacity-100 text-center">
                  <li className="lg:text-xl sm:text-sm text-base">• High Performance Armor</li>
                  <li className="lg:text-xl sm:text-sm text-base">• Complete Balistic Protection <br /> and Guard</li>
                </ul>
              </p>
            </div>
          </Rodal>

          <svg width={14} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="blinking absolute z-[8] top-[358px] left-[49.5%]">
            <path fill="white" d="M448 256A192 192 0 1 0 64 256a192 192 0 1 0 384 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm256 80a80 80 0 1 0 0-160 80 80 0 1 0 0 160zm0-224a144 144 0 1 1 0 288 144 144 0 1 1 0-288zM224 256a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"/>
          </svg>
          <div className="absolute z-[9] top-[328px] left-[46.7%] " >
            <Tooltip content="Clothing" className="whitespace-nowrap" style={`light`} placement="left">
              <button onClick={() => setClothing(true)} className="rounded-xl bg-yellow-600 opacity-0 border border-white px-11 py-16"></button>
            </Tooltip>
          </div>
          <Rodal width={700} height={570} className="rounded-xl" visible={clothing} animation={'slideUp'} duration={400} onClose={() => {setClothing(false); const myVid =document.getElementById("vidClothing"); myVid.currentTime = 0; myVid.play();}}>
            <div className="h-fit lg:p-0 px-4 py-4 rounded-xl">
              {/* <img src="/quest-baru-01.png" alt="sebuah gambar quest baru" className="h-full w-full" /> */}
              <video id="vidClothing" width="1280" height="720" autoPlay muted >
                <source src={`/video-isi-clothing.mkv`} type="video/mp4"/>
                Your browser does not support the video tag.
              </video>
              <h2 className="text-black text-3xl font-bold text-center">Clothing</h2>
              <p className="text-black text-sm mt-3">
                <ul className="opacity-100 text-center">
                  <li className="lg:text-xl sm:text-sm text-base">• Smart Textiles for Visible</li>
                  <li className="lg:text-xl sm:text-sm text-base">• IR Camouflage</li>
                </ul>
              </p>
            </div>
          </Rodal>

          <svg width={14} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="blinking absolute z-[8] top-[488px] left-[52%]">
            <path fill="white" d="M448 256A192 192 0 1 0 64 256a192 192 0 1 0 384 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm256 80a80 80 0 1 0 0-160 80 80 0 1 0 0 160zm0-224a144 144 0 1 1 0 288 144 144 0 1 1 0-288zM224 256a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"/>
          </svg>
          <div className="absolute z-[9] top-[468px] left-[45.5%] " >
            <Tooltip content="Exo-Skeleton" className="whitespace-nowrap" style={`light`} placement="right">
              <button onClick={() => setExoSkeleton(true)} className="rounded-xl bg-yellow-600 opacity-0 border border-white px-14 py-8"></button>
            </Tooltip>
          </div>
          <Rodal width={700} height={570} className="rounded-xl" visible={exoSkeleton} animation={'slideUp'} duration={400} onClose={() => {setExoSkeleton(false); const myVid =document.getElementById("vidExo"); myVid.currentTime = 0; myVid.play();}}>
            <div className="h-fit lg:p-0 px-4 py-4 rounded-xl">
              {/* <img src="/quest-baru-01.png" alt="sebuah gambar quest baru" className="h-full w-full" /> */}
              <video id="vidExo" width="1280" height="720" autoPlay muted >
                <source src={`/video-isi-exo.mkv`} type="video/mp4"/>
                Your browser does not support the video tag.
              </video>
              <h2 className="text-black text-3xl font-bold text-center">Exo Skeleton</h2>
              <p className="text-black text-sm mt-3">
                <ul className="opacity-100 text-center">
                  <li className="lg:text-xl sm:text-sm text-base">• Improve Performance & Endurance</li>
                </ul>
              </p>
            </div>
          </Rodal>

          <div className="grid sm:grid-cols-2 grid-cols-1 absolute lg:top-24 sm:top-16 top-8 left-0 w-full">
            <Fade left cascade>
            <div className="lg:pl-44 sm:pl-32 pl-7">
              <h2 className="text-[#EF901C] lg:text-4xl sm:text-4xl lg:mb-3 text-5xl font-bold">THE</h2>
              <h2 className="text-white lg:text-6xl sm:text-4xl text-5xl lg:-mt-4 font-bold">ARMY GEAR</h2>
            </div>
            </Fade>
            {/* <Fade right cascade>
            <div className="lg:pr-64 sm:pr-28 pr-0 lg:pl-0 pl-7 sm:ml-auto sm:mt-0 mt-4">
              <h2 className="text-[#EF901C] lg:text-4xl lg:mb-3 text-2xl font-bold">HEAD GEAR</h2>
              <div className="bg-[#DDD] text-white pl-5 lg:pr-8 pr-5 py-3 bg-opacity-0 rounded-lg mt-1 lg:ml-8 ml-0 w-fit">
                <ul className="opacity-100">
                  <li className="lg:text-xl sm:text-sm text-base">• Extreme Head Protection</li>
                  <li className="lg:text-xl sm:text-sm text-base">• Enhance Awareness</li>
                  <li className="lg:text-xl sm:text-sm text-base">• Provide Critical Data</li>
                </ul>
              </div>
            </div>
            </Fade>
            <Fade left cascade>
            <div className="lg:pr-32 sm:pr-20 pr-0 lg:pl-0 pl-7 sm:ml-auto lg:mt-16 sm:mt-8 mt-4">
              <h2 className="text-[#EF901C] lg:text-4xl lg:mb-3 text-2xl font-bold lg:ml-4">CLOTHING</h2>
              <div className="bg-[#DDD] text-white pl-5 lg:pr-8 pr-5 py-3 bg-opacity-0 rounded-lg mt-1 w-fit">
                <ul className="opacity-100">
                  <li className="lg:text-xl sm:text-sm text-base">• Smart Textiles for Visible</li>
                  <li className="lg:text-xl sm:text-sm text-base">• IR Camouflag</li>
                </ul>
              </div>
            </div>
            </Fade>
            <Fade right cascade>
            <div className="lg:pr-32 sm:pr-12 pr-0 lg:pl-0 pl-7 sm:ml-auto lg:mt-28 sm:mt-20 mt-4">
              <h2 className="text-[#EF901C] lg:text-4xl lg:mb-3 text-2xl font-bold">ARMOR & SENSOR</h2>
              <div className="bg-[#DDD] text-white pl-5 lg:pr-8 pr-5 py-3 bg-opacity-0 rounded-lg mt-1 lg:ml-8 ml-0 w-fit">
                <ul className="opacity-100">
                  <li className="lg:text-xl sm:text-sm text-base">• High Performance Armor</li>
                  <li className="lg:text-xl sm:text-sm text-base">• Complete Balistic Protection <br /> and Guard</li>
                </ul>
              </div>
            </div>
            </Fade>
            <Fade left cascade>
            <div className="lg:pr-20 sm:pr-10 pr-0 lg:pl-0 pl-7 sm:ml-auto lg:mt-16 mt-4">
              <h2 className="text-[#EF901C] lg:text-4xl lg:mb-3 text-2xl leading-[1] font-bold lg:ml-4">EXO-SKELETON</h2>
              <div className="bg-[#DDD] text-white pl-5 lg:pr-8 pr-5 py-3 bg-opacity-0 rounded-lg mt-1 w-fit">
                <ul className="opacity-100">
                  <li className="lg:text-xl sm:text-sm text-base">• Improve Performance & Endurance</li>
                  <li className="lg:text-xl sm:text-sm text-base">• IR Camouflag</li>
                </ul>
              </div>
            </div>
            </Fade>
            <Fade right cascade>
            <div className="lg:pr-32 sm:pr-4 pr-0 lg:ml-auto lg:pl-0 pl-7 lg:mt-28 sm:mt-24 mt-4">
              <h2 className="text-[#EF901C] lg:text-4xl lg:mb-3 text-2xl font-bold lg:pl-28">COMMUNICATION SYSTEM</h2>
              <div className="bg-[#DDD] text-white lg:pr-8 pr-5 py-3 pl-5 bg-opacity-0 rounded-lg mt-1 lg:ml-36 w-fit">
                <ul className="opacity-100">
                  <li className="lg:text-xl sm:text-sm text-base">• Data Fusion and Real Time</li>
                  <li className="lg:text-xl sm:text-sm text-base">• Tactical Communication</li>
                </ul>
              </div>
            </div>
            </Fade> */}
            
          </div>
        </div>
      </div>
    </section>
    <About/>
    <Footer/>
    </>
  )
}

export default GearArmy
import { Link, useLocation } from "react-router-dom";

function Nav() {
  const navs = [
    {
      'link': '',
      'title': 'Homepage',
    },
    {
      'link': 'overview',
      'title': 'Overview',
    },
    {
      'link': 'feature',
      'title': 'Feature',
    },
    {
      'link': 'accessories',
      'title': 'Accessories',
    },
  ];

  const location = useLocation()
  console.log(location);
  const handleClickScroll = (section) => {
    const element = document.getElementById(section);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  
  return (
    <nav className="fixed w-full z-30 top-0 left-0">
      <div className="bg-amber-500 dark:bg-gray-900 ">
        <div className="max-w-screen-xl mx-auto p-4 relative">
          <h2 className="text-white lg:text-4xl sm:text-4xl text-2xl font-bold lg:mx-auto mx-3 lg:text-center sm:text-center text-right">STRIDE Virtual Reality</h2>
          <div className="absolute top-0 lg:left-24 sm:left-20 left-2 bg-[#E06D1D] rounded-b-full lg:pt-7 sm:pt-8 pt-5 lg:px-3 px-2 lg:pb-3 pb-2">
            <div className="rounded-full bg-white lg:p-4 p-2">
              <img src={`/stride-logo.png`} alt="Logo Stride" width="40px"/>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#131313] w-full">
        <div className="max-w-screen-xl mx-auto lg:py-2 py-0">
          <ul
            className="flex p-0 lg:px-0 px-3 mt-0 font-medium rounded-lg flex-row md:space-x-8 lg:justify-center sm:justify-center justify-end">
            {navs.map((nav, index) => (
              <li key={`${index}`}>
                <Link to={`/${nav.link}`} onClick={() => handleClickScroll(nav.link)} className={`text-white px-3 py-1 hover:bg-[#462d1d] ${location.pathname === '/'+nav.link ? 'bg-[#462d1d]' : ''} rounded-lg`} aria-current="page">{nav.title}</Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default Nav;
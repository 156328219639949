import './App.css';
import 'rodal/lib/rodal.css';
import Nav from './components/Nav';
import Footer from './components/Footer';
import About from './pages/homepage/About';
import FutureSoldiers from './pages/homepage/FutureSoldiers';
import GearArmy from './pages/homepage/GearArmy';
import Hero from './pages/homepage/Hero';
import MetaQuest from './pages/homepage/MetaQuest';

function App() {

  return (
    <div className="App">
      <Nav />
      {/* <Hero />
      <FutureSoldiers/>
      <GearArmy />
      <About/>
      <MetaQuest /> */}
      <Footer />
    </div>
  );
}

export default App;

import { Fade } from "react-reveal";
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";

function FutureSoldiers() {
  return (
    <>
    <Nav/>
    <section id="overview"
      className={`bg-[#232323] border-b-[4px] border-[#EF901C] relative lg:h-[800px] h-[500px] bg-cover future mt-16`}>
      {/* <img src="/bg-about.jpg" alt="Background About Future Soldier"
        className="col-span-12 object-cover object-top h-full"/> */}
      <div className=" max-w-screen-xl grid grid-cols-12 mx-auto lg:pt-36 pt-20">
        
        <div className="col-span-4 lg:block hidden">
          <h2> </h2>
        </div>
        <Fade right cascade>
        <div className="lg:col-span-7 col-span-12 lg:px-0 sm:px-24 px-7">
          <h2 className="text-[#EF901C] text-4xl font-medium">THE</h2>
          <h2 className="text-white lg:text-[5rem] text-4xl leading-[1] font-bold">FUTURE SOLDIERS</h2>
          <p
            className="text-white lg:text-md text-sm lg:mt-10 sm:mt-10 mt-4 lg:leading-[1.75] leading-[1.25] font-semibold">
            "Central to STRIDE's success is its emphasis on research and development. Collaborations with leading research institutions, tech companies, and academia foster an environment of continuous innovation. This synergy results in groundbreaking solutions such as smart uniforms that regulate body temperature, self-repairing equipment, and adaptive camouflage that seamlessly blends soldiers with their surroundings. Malaysia's future soldier emerges as a testament to the nation's intellectual prowess and its relentless pursuit of defense excellence.
            <br/> <br/>
            However, the STRIDE program extends beyond the confines of laboratories and military installations. It reaches out to the hearts and minds of the nation's youth, extending an invitation to join this journey of technological evolution. By engaging with the younger generation, STRIDE seeks to ignite interest in science, technology, engineering, and mathematics (STEM), fostering a pool of talented individuals who will continue to drive Malaysia's defense innovation forward. Through workshops, mentorship programs, and collaborative projects, the program aims to inspire young minds to dream big and actively contribute to the defense technology landscape.
            
          </p>
          <button
            className=" bg-[#EF901C] text-white lg:text-xl text-base px-6 py-3 rounded-lg lg:mt-16 sm:mt-10 mt-4 font-semibold">Download
            Brochure</button>
        </div>
        </Fade>
      </div>
    </section>
    <Footer/>
    </>
  )
}

export default FutureSoldiers;